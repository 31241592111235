define("discourse/plugins/discourse-chatbot/discourse/components/chatbot-launch", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/object", "discourse/lib/utilities", "discourse/models/composer", "I18n", "discourse/models/user", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _service, _object, _utilities, _composer, _I18n, _user, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showChatbotButton}}
    <DButton
      {{did-insert this.getBotUser}}
      @id="chatbot-btn"
      @class="btn-primary chatbot-btn"
      @action={{action this.startChatting}}
    >
      {{#if this.chatbotLaunchUseAvatar}}
        {{avatar this.botUser imageSize="medium"}}
      {{else}}
        {{d-icon this.chatbotLaunchIcon}}
      {{/if}}
      <label class="d-button-label">{{i18n "chatbot.title_capitalized"}}</label>
    </DButton>
  {{/if}}
  */
  {
    "id": "Oai+PQ39",
    "block": "[[[41,[30,0,[\"showChatbotButton\"]],[[[1,\"  \"],[8,[39,1],[[4,[38,3],[[30,0,[\"getBotUser\"]]],null]],[[\"@id\",\"@class\",\"@action\"],[\"chatbot-btn\",\"btn-primary chatbot-btn\",[28,[37,2],[[30,0],[30,0,[\"startChatting\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"chatbotLaunchUseAvatar\"]],[[[1,\"      \"],[1,[28,[35,4],[[30,0,[\"botUser\"]]],[[\"imageSize\"],[\"medium\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,5],[[30,0,[\"chatbotLaunchIcon\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[10,\"label\"],[14,0,\"d-button-label\"],[12],[1,[28,[35,6],[\"chatbot.title_capitalized\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\",\"did-insert\",\"avatar\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-chatbot/discourse/components/chatbot-launch.hbs",
    "isStrictMode": false
  });
  let ContentLanguageDiscovery = _exports.default = (_class = class ContentLanguageDiscovery extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "chat", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "composer", _descriptor5, this);
      _initializerDefineProperty(this, "botUser", _descriptor6, this);
    }
    get showChatbotButton() {
      const {
        currentRouteName
      } = this.router;
      return this.currentUser && this.siteSettings.chatbot_enabled && currentRouteName === `discovery.${(0, _utilities.defaultHomepage)()}` && this.siteSettings.chatbot_quick_access_talk_button && (this.siteSettings.chat_enabled && this.siteSettings.chatbot_permitted_in_chat || this.siteSettings.chatbot_permitted_in_private_messages && this.siteSettings.chatbot_quick_access_talk_in_private_message);
    }
    getBotUser() {
      _user.default.findByUsername(this.siteSettings.chatbot_bot_user, {}).then(user => {
        this.botUser = user;
      });
    }
    get chatbotLaunchUseAvatar() {
      return this.siteSettings.chatbot_quick_access_bot_user_icon === "";
    }
    get chatbotLaunchIcon() {
      return this.siteSettings.chatbot_quick_access_bot_user_icon;
    }
    startChatting() {
      if (!this.siteSettings.chatbot_quick_access_talk_in_private_message) {
        this.chat.upsertDmChannelForUsernames([this.siteSettings.chatbot_bot_user]).then(chatChannel => {
          this.router.transitionTo("chat.channel", ...chatChannel.routeModels);
        });
      } else {
        this.composer.focusComposer({
          fallbackToNewTopic: true,
          openOpts: {
            action: _composer.default.PRIVATE_MESSAGE,
            recipients: this.siteSettings.chatbot_bot_user,
            topicTitle: _I18n.default.t("chatbot.pm_prefix"),
            archetypeId: "private_message",
            draftKey: _composer.default.NEW_PRIVATE_MESSAGE_KEY,
            hasGroups: false,
            warningsDisabled: true
          }
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "composer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "botUser", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getBotUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getBotUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startChatting", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "startChatting"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContentLanguageDiscovery);
});